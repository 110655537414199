import React, { useState } from 'react';
import {
  Container, TextField, MenuItem, Button, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, CircularProgress
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';

const dataTypes = [
  { label: 'Keystrokes', value: 'keystrokes' },
  { label: 'Activities', value: 'activities' },
  { label: 'Screenshots', value: 'screenshots' },
  { label: 'Audios', value: 'audios' },
  { label: 'System Info', value: 'system_info' },
];

function App() {
  const [dataType, setDataType] = useState('');
  const [userId, setUserId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [timezone, setTimezone] = useState('UTC');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');

  const handleQuery = async () => {
    if (!dataType || !userId || (dataType !== 'system_info' && (!startDate || !endDate))) {
      alert('Please fill in all fields');
      return;
    }

    const params = {
      data_type: dataType,
      user_id: userId,
      start_date: startDate ? startDate.toISOString() : undefined,
      end_date: endDate ? endDate.toISOString() : undefined,
      // timezone: timezone,
    };

    setLoading(true);
    setResults(null);

    try {
      const response = await axios.get('https://us-central1-go2-desktop-agent-backend-prod.cloudfunctions.net/qa_api', { params });
      setResults(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Error fetching data. Check the console for more details.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Desktop QA Tool
      </Typography>
      <TextField
        select
        label="Data Type"
        value={dataType}
        onChange={(e) => setDataType(e.target.value)}
        fullWidth
        margin="normal"
      >
        {dataTypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="User ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        fullWidth
        margin="normal"
      />
      {dataType !== 'system_info' && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
        </>
      )}
      {/* <TextField
        label="Timezone"
        value={timezone}
        onChange={(e) => setTimezone(e.target.value)}
        fullWidth
        margin="normal"
      /> */}
      <Button variant="contained" color="primary" onClick={handleQuery} fullWidth>
        Query Data - USE UTC. so if you are in ph timezone -1 day.
      </Button>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : results !== null ? (
        results.length > 0 ? (
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'timestamp' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'timestamp'}
                      direction={orderBy === 'timestamp' ? order : 'asc'}
                      onClick={() => handleRequestSort('timestamp')}
                    >
                      Timestamp
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Checkmark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(results, getComparator(order, orderBy)).map((row) => (
                  <TableRow key={row.date}>
                    <TableCell>{row.date}</TableCell>
                    {/* <TableCell><CheckIcon /></TableCell> */}
                    <TableCell>{row.exists ? <CheckIcon />: "Doesn't Exist"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" style={{ marginTop: '20px', textAlign: 'center' }}>
            Zero results
          </Typography>
        )
      ) : null}
    </Container>
  );
}

export default App;
